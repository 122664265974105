'use client';

import { useParams } from 'next/navigation';
import React, { useEffect } from 'react';

const template = ({ children }) => {
  const params = useParams();
  const groupId = params.groupId;
  useEffect(() => {
    if (groupId) {
      localStorage.setItem('groupId', groupId);
    }
  }, [groupId]);
  return (
    <div className="w-full h-full" key={groupId}>
      {children}
    </div>
  );
};

export default template;
